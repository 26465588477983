import { IService, ServiceFormat, ServiceListType } from '../types';
import { ServiceForClientsType  } from './types';

export const servicesList: IService<ServiceForClientsType>[] = [
    {
        type: ServiceForClientsType.LINES,
        title: 'Аналіз ліній зовнішності',
        details: {
            price: '100 доларів',
            format: ServiceFormat.ONLINE,
            ETA: '7-10 робочих днів з моменту заповнення анкети та надсилання фото.',
            lists: [
                {
                    title: 'Ця послуга потрібна вам,  якщо бажаєте розібратись:',
                    type: ServiceListType.NEED_BECAUSE,
                    items: [
                        {text: 'який у вас тип фігури, підтон шкіри, контрастність;',},
                        {text: 'які фасони, силуети, фактури, форми та довжини обирати, щоб підкреслити свою зовнішність;',},
                        {text: 'як працювати з кольорами та їх насиченістю в образах;',},
                        {text: 'як обирати прикраси та аксесуари.',},
                    ]
                },
                {
                    title: 'Схема роботи:',
                    type: ServiceListType.SCHEME,
                    items: [
                        {text: 'короткий зідзвон для знайомства та формування індивідуального запиту;',},
                        {text: 'заповнення анкети (надсилання фото портретної зони і фігури). Пам’ятка як правильно' +
                                ' зробити фото додається;',},
                        {text: 'зідзвон для представлення результатів роботи.',},
                    ]
                },
                {
                    title: 'Що отримаєте:',
                    type: ServiceListType.BENEFITS,
                    items: [
                        {text: 'презентацію, де буде продемонстровано: аналіз ваших ліній  зовнішності, рекомендації' +
                                ' щодо гармонійних кольорів, фасонів, силуетів, фактур, форм та довжин; формули образів, відповідно до вашого запиту та ліній зовнішності;',},
                        {text: 'запис фінальної зустрічі, яку зможете у будь-який момент передивитись та переслухати;',},
                        {text: 'мою підтримку протягом двох тижнів після консультації. Зможете задати будь-які питання, що виникнуть після наданої інформації.',},
                    ],
                },
            ],
        },
    },
    {
        type: ServiceForClientsType.WARDROBE_REVIEW,
        title: 'Розбір гардеробу + шопінг-супровід',
        details: {
            price: '200 доларів як для online, так і для offline послуги. У випадку наявності великої кількості позицій для розбору (понад 70), ціна коригується.',
            format: ServiceFormat.ALL,
            ETA: '7-10 робочих днів з моменту заповнення анкети та надсилання фото (або проведення розбору речей в форматі offline).',
            importantNote: 'Гардероб розбирається по сезонам: зима/осінь АБО весна/літо.',
            lists: [
                {
                    title: 'Ця послуга потрібна вам, якщо:',
                    type: ServiceListType.NEED_BECAUSE,
                    items: [
                        {text: 'речей багато, але все набридло/не подобається і вдягти «нічого»;'},
                        {text: 'маєте сумніви щодо актуальності або доречності більшості речей ;'},
                        {text: 'не знаєте, як складати комплекти з наявних позицій;'},
                        {text: 'хочете розібратися одяг та аксесуари яких силуетів, фасонів, форм та кольорів' +
                                ' купувати, щоб вони «працювали» у вашому гардеробі.'},
                    ],
                },
                {
                    title: 'Схема роботи:',
                    type: ServiceListType.SCHEME,
                    items: [
                        {text: 'короткий зідзвон для знайомства та формування індивідуального запиту;'},
                        {text: 'заповнення анкети (у тому числі надсилання фото портретної зони і фігури). Пам’ятка' +
                                ' як правильно зробити фото додається;'},
                        {text: 'проведення мною розбору гардеробу в форматі offline АБО надсилання фото позицій з вашого гардеробу у випадку надання послуги online. Пам’ятка як правильно зробити фото додається.'},
                        {text: 'зідзвон для представлення результатів роботи.'},
                    ],
                },
                {
                    title: 'Що отримаєте:',
                    type: ServiceListType.BENEFITS,
                    items: [
                        {text: 'презентацію, де буде продемонстровано: аналіз ваших ліній зовнішності; розбір гардеробу з аргументаціями; шопінг-лист, відповідно до запиту і бюджету з конкретними позиціями; сформовано капсулу на сезон, що розбираємо;'},
                        {text: 'запис фінальної зустрічі, яку ви зможете у будь-який момент передивитись та переслухати;'},
                        {text: 'мою підтримку протягом двох тижнів після консультації. Зможете задати будь-які питання, що виникають.'},
                    ],
                },
            ],
            listSummary: ['Тобто, фактично ви отримуєте дві послуги - розбір гардеробу та шопінг-супровід. Саме такий підхід, на мою думку, є органічним та ефективним.'],
        },
    },
    {
        type: ServiceForClientsType.CUPSULA,
        title: 'Формування капсули',
        details: {
            price: '200 доларів',
            format: ServiceFormat.ONLINE,
            ETA: ' 7-10 робочих днів з моменту надсилання фото та заповнення анкети.',
            lists: [
                {
                    title: 'Ця послуга потрібна вам, якщо:',
                    type: ServiceListType.NEED_BECAUSE,
                    items: [
                        {text: 'ви прихильник невеликого гардеробу, де всі речі ідеально поєднуються між собою;'},
                        {text: 'цінуєте власний час або/та не любите довго думати, що вдягти;'},
                        {text: 'бажаєте переписати свою гардеробну історію з чистого аркушу, і оновити більшість позицій;'},
                        {text: 'хочете скласти комплекти для певних життєвих обставин - на сезон/в поїздку/в офіс' +
                                ' тощо.'},
                    ]
                },
                {
                    title: 'Схема роботи:',
                    type: ServiceListType.SCHEME,
                    items: [
                        {text: 'короткий зідзвон для знайомства та формування індивідуального запиту;',},
                        {text: 'заповнення анкети (та надсилання фото портретної зони і фігури). Пам’ятка як' +
                                ' правильно зробити фото додається;',},
                        {text: 'надсилання фото позицій з вашого гардеробу (відповідно до запиту). Пам’ятка як' +
                                ' правильно зробити фото додається;',},
                        {text: 'зідзвон для представлення результатів роботи.',},
                    ]
                },
                {
                    title: 'Що отримаєте:',
                    type: ServiceListType.BENEFITS,
                    items: [
                        {text: 'презентацію, де буде продемонстровано: аналіз ваших ліній  зовнішності, складену' +
                                ' капсулу (20-40 готових комплектів) та шопінг-лист, відповідно до запиту і бюджету з конкретними позиціями;',},
                        {text: 'запис фінальної зустрічі, яку ви зможете у будь-який момент передивитись та переслухати;',},
                        {text: 'мою підтримку протягом двох тижнів після консультації. Зможете задати будь-які питання, що виникають. Наприклад, скидати фото речей, які ви придбали з шопінг-листа (або зробили вибір речі самостійно), задля отримання поради щодо розміру, посадки тощо.',},
                    ],
                },
            ]
        },
    },
    {
        type: ServiceForClientsType.WARDROBE_STYLING,
        title: 'Стайлінг гардеробу',
        details: {
            price: 'від 50 доларів (в залежності від кількості речей)',
            format: ServiceFormat.ONLINE,
            ETA: ' 7-10 робочих днів з моменту надсилання фото.',
            lists: [
                {
                    title: 'Ця послуга потрібна вам, якщо:',
                    type: ServiceListType.NEED_BECAUSE,
                    items: [
                        {text: 'ви вже були моїм клієнтом, і замовляли послугу розбір гардеробу або/та формування капсули;'},
                        {text: 'ви придбали одну чи декілька позицій, і бажаєте максимально вдало та функціонально вписати її в свій гардероб.'},
                    ]
                },
                {
                    title: 'Схема роботи:',
                    type: ServiceListType.SCHEME,
                    items: [
                        {text: 'короткий зідзвон для формування індивідуального запиту;'},
                        {text: 'надсилання фото придбаних позицій. Пам’ятка як правильно зробити фото додається;'},
                        {text: 'зідзвон для представлення результатів роботи.'},
                    ]
                },
                {
                    title: 'Що отримаєте:',
                    type: ServiceListType.BENEFITS,
                    items: [
                        {text: 'презентацію, де буде продемонстровано: складені комплекти на відповідний сезон за запитом;',},
                        {text: 'запис фінальної зустрічі, яку ви зможете у будь-який момент передивитись та переслухати;',},
                        {text: 'мою підтримку протягом двох тижнів після консультації. Зможете задати будь-які питання, що виникають.',},
                    ],
                },
            ]
        },
    },
    {
        type: ServiceForClientsType.STYLIST_FOR_YEAR,
        title: 'Стиліст на рік',
        details: {
            price: '700 доларів',
            format: ServiceFormat.ALL,
            ETA: '7-10 робочих днів для кожної послуги або за запитом',
            lists: [
                {
                    title: 'Ця послуга потрібна вам, якщо:',
                    type: ServiceListType.NEED_BECAUSE,
                    items: [
                        {text: 'бажаєте повністю делегувати роботу зі стилем;'},
                        {text: 'хочете глибинно пропрацювати свій гардероб та створити стильовий вектор на найближчі роки.'},
                    ],
                },
                {
                    title: 'Що входить у дану послугу:',
                    type: ServiceListType.SERVICE_CONTAINS,
                    items: [
                        {text: 'розбір ліній зовнішності;'},
                        {text: '2 розбори гардеробу + шопінг-супроводи (на сезон весна/літо та осінь/зима' +
                                ' відповідно);'},
                        {text: '1 капсула за будь-яким запитом;'},
                        {text: '2 послуги стайлінгу гардеробу.'},
                    ],
                },
            ],
            listSummary: ['Опис зазначених послуг читайте вище'],
        },
    },
]

